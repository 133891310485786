@import "../../resources/scss/variables";

.loader {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left !important;
    flex-wrap: wrap;

    & img {
        width: 16%;
    }

    & .outer,
    .middle,
    .inner {
        border: 12px solid transparent;
        // border-top-color: $color-active;
        // border-right-color: $color-active;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
    }

    & .outer {
        width: 26.5em;
        height: 26.5em;
        margin-left: -14.7em;
        margin-top: -13.7em;
        animation: spin 2s linear infinite;
        border-top-color: #6944AC;
        border-right-color: #6944AC;
    }

    & .middle {
        width: 22.5em;
        height: 22.5em;
        margin-left: -12.75em;
        margin-top: -11.75em;
        animation: spin 1.75s linear reverse infinite;
        border-top-color: #04A3B6;
        border-bottom-color: #04A3B6;
        border-right-color: #04A3B6;
        border-left-color: #04A3B6;
    }

    & .inner {
        width: 12.5em;
        height: 12.5em;
        margin-left: -6.75em;
        margin-top: -6.75em;
        animation: spin 1.5s linear infinite;
        border-top-color: $color-active;
        border-right-color: $color-active;
    }
}


@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}